@import "@variables"; @import "@mixin";
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;

  > li {
    margin-right: 10px;

    .menuButton {
      border: 1px solid $theme-color;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      padding: 0 10px;
      color: $theme-color;
      font-size: $font-14px;
      line-height: 48px;
      height: 48px;
      box-sizing: border-box;

      &Register {
        background-color: $theme-color;
        color: $white-1;
        width: 152px;

        &:hover {
          background-color: rgba($theme-color, 0.8) !important;
        }
    
        &:active {
          background-color: rgba($theme-color, 0.8) !important;
        }
      }

      &:hover {
        background-color: rgba($theme-color, 0.04);
      }
  
      &:active {
        background-color: rgba($theme-color, 0.08);
      }
    }

    .ownerLink {
      display: flex;
      gap: 8px;
      color: $theme-color;
      font-size: $font-14px;
      font-weight: bold;
      line-height: 24px;
    }

    &:last-child {
      background-color: $theme-color;

      &:hover {
        background-color: $theme-color-hover;
      }
      &:active {
        background-color: $theme-color-active;
      }
      > a {
        color: $white-1;
      }
    }
    &:last-child {
      margin-right: 0;
    }

    &.switchLang {
      background-color: transparent;
      height: auto;
      border: none;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.switchLang {
  &Block {
    position: relative;
  }

  &Button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: $font-12px;

    &Text {
      margin: 0 6px 0 5px;
    }

    &Icon {
      width: 20px;
    }
  }

  &Box {
    position: absolute;
    top: 26px;
    left: 0;
    width: 101px;
    background-color: $white-1;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.24);
  }

  &List {
    &Item {
      padding: 8px 0;

      &:hover, 
      &Selected {
        background-color: $gray-1;
      }
    }
  }

  &Link {
    display: block;
    font-size: $font-12px;
    text-align: center;
  }
}

.auth {
  &Image {
    width: 40px;
    height: 40px;
  }

  &Link {
    display: inline-block;
    text-decoration: none;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }
}

@include screen-mobile {
  .menu {
    display: initial;
    flex-direction: initial;
    align-items: initial;
    > li {
      width: 100%;
      height: 44px;
      margin-right: initial;
      border-radius: 8px;
      margin-bottom: 10px;
      background-color: $white-1;
      > a {
        justify-content: center;
        font-size: $font-16px;
        font-weight: bold;
      }

      &.switchLang {
        display: none;
      }  
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
