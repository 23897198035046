@import "@variables"; @import "@mixin";
.sideMenu {
  width: 100%;
  max-width: 185px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 26px;
  margin-top: 50px;
  background-color: $white-1;
}

.menu {
  display: flex;
  flex-direction: column;
  > li {
    display: inherit;
    align-items: center;
    height: 40px;
    padding-left: 24px;
    &:hover {
      background-color: rgba($theme-color, 0.1);
      border-top-right-radius: 44px;
      border-bottom-right-radius: 44px;
    }
    > a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: bold;
      color: $gray-2;
      font-size: $font-12px;
      > img {
        max-width: 20px;
        margin-right: 10px;
      }
    }
  }
}

.buttonMenu {
  display: flex;
  height: 48px;
  border-radius: 100px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border: 1px solid $theme-color;
  background-color: $white-1;
  font-size: $font-14px;
  font-weight: 700;
  line-height: 150%;
  color: $theme-color;
  cursor: pointer;
  width: 100%;

  &Active {
    background-color: $theme-color;
    color: $white-1;
  }

  &List {
    margin: 48px 24px 0 24px;
    display: grid;
    gap: 16px;
  }
}

.ownerMenu {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid $black-2;
  margin-top: 20px;
  padding-top: 20px;
  > a {
    display: inherit;
    align-items: inherit;
    width: inherit;
    height: 40px;
    padding: 0 24px;
    font-weight: 700;
    color: $gray-2;
    font-size: $font-12px;
    &:hover {
      background-color: rgba($theme-color, 0.1);
      border-top-right-radius: 44px;
      border-bottom-right-radius: 44px;
    }
  }
}

.openIntercom {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: $gray-2;
  font-size: $font-12px;
  cursor: pointer;
  > img {
    max-width: 20px;
    margin-right: 10px;
  }
}

.logout {
  margin: 16px 24px 0 24px;
  display: flex;
  justify-content: center;

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    border: 1px solid $theme-color;
    border-radius: 25px;
    padding: 0 20px;
    color: $theme-color;
    font-size: $font-12px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
  
    &:hover {
      background-color: rgba($theme-color, 0.04);
    }
    &:active {
      background-color: rgba($theme-color, 0.08);
    }
  }  
}


.accordionMenu {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-bottom: 5px;

  &Title {
    font-weight: bold;
    color: $black-2;
    font-size: $font-12px;
    margin-right: 10px;
  }
}

.subMenu {
  padding-left: 55px;
  > li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: initial;
    }
  }
}

.language {
  cursor: pointer;
  font-size: $font-12px;
  font-weight: bold;
  line-height: 20px;
  color: $gray-6;
  &:hover {
    color: $black-2;
  }
}

@include screen-mobile {
  .sideMenu {
    max-width: initial;
    top: 50px;
    padding-top: initial;
    padding-bottom: 150px;
    margin-top: initial;
    background-color: $blue-4;
    z-index: 3;
    overflow-y: scroll;

    &ShowSmartBanner {
      top: calc(50px + 70px);
    }
  }

  .menu {
    > li {
      display: inherit;
      align-items: center;
      height: 60px;
      padding-left: 24px;
      &:hover {
        background-color: initial;
        border-top-right-radius: initial;
        border-bottom-right-radius: initial;
      }
      > a {
        font-size: $font-20px;
        &:active {
          color: $theme-color;
        }
        > img {
          max-width: 24px;
          margin-right: 28px;
        }

        .menuArrowIcon {
          max-width: 24px;
          margin-left: 4px;
          margin-right: 0;
          transform: translateY(1px) rotate(-90deg);
        }
      }
    }

    &Partner {
      margin-top: 40px;
    }
  }

  .openIntercom {
    font-size: $font-20px;
    &:active {
      color: $theme-color;
    }
    > img {
      max-width: 24px;
      margin-right: 28px;
    }
  }

  .accordionMenu {
    &Title {
      font-weight: bold;
      color: $black-2;
      font-size: $font-20px;
      margin-right: initial;
    }

    &LangIcon {
      max-width: 24px;
      margin-right: 28px;    
    }  

    &ArrowIcon {
      max-width: 24px;
      margin-left: 4px;

      &Active {
        transform: rotate(180deg);
      }
    }  
  }

  .subMenu {
    padding-left: 75px;
    > li {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: initial;
      }
    }
  }

  .language {
    font-size: $font-16px;
    line-height: 24px;
  }

  .headerMenu {
    margin: 30px 20px 0;
    padding-top: 30px;
    border-top: 2px solid $theme-color;
  }

  .ownerMenu {
    width: initial;
    margin: 30px 20px 0;
    border-top: 2px solid $theme-color;
    > a {
      width: 100%;
      height: 60px;
      font-weight: bold;
      color: $gray-2;
      font-size: $font-16px;
      &:hover {
        background-color: initial;
        border-top-right-radius: initial;
        border-bottom-right-radius: initial;
      }
    }
  }

  .logout {
    &Button {
      justify-content: center;
      width: 100%;
      height: 44px;
      margin-right: initial;
      border-radius: 8px;
      margin-bottom: 10px;
      background-color: $white-1;
      font-size: $font-16px;
      font-weight: bold;
      &:hover {
        background-color: rgba($theme-color, 0.04);
      }
      &:active {
        background-color: rgba($theme-color, 0.08);
      }
    }
  }
}

.warningIcon {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-right: 0px !important;
  margin-top: 2px;
}